import { EventEmitter, Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
  ) { }

  // getPageData(){
  //   return this.http.get(
  //     environment.baseUrl+`business/template/page`
  //   )
  // }
  getTempDataById(){
    return this.http.get(
      environment.baseUrl+`business/template/1eda0948-7f25-6160-80d4-b99e60e79e76`
    )
  }

  getTemplateDataByRouteName(templateName:any){
    return this.http.get(
      environment.baseUrl+`business/template/route?routeName=${templateName}`
    )
  }

  postPageData(data:any){
    return this.http.post(
      environment.baseUrl+`business/template/page`,data
    )
  }
  getPageData(templateId:any,pageId:any){
    return this.http.get(
      environment.baseUrl+`business/template/page?templateId=${templateId}&pageId=${pageId}`
    )
  }

  getListOfWebsites(){
    return this.http.get(
      environment.baseUrl+`business/template?size=100&page=0`
    )
  }
  getWebsitesBasedOnBusiness(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/business/website?size=100&page=0&businessId=${businessId}`
    )
  }
  getWebsitesBasedOnWithoutBusiness(){
    return this.http.get(
      environment.baseUrl+`business/business/website?size=100&page=0`
    )
  }

  updateWebsiteDomain(data:any){
    return this.http.put(
      environment.baseUrl+`business/business/website`,data
    )
  }
  attachWebsiteToBusiness(data:any){
    return this.http.put(
      environment.baseUrl+`business/business/template`,data
    )
  }
  getTemplateDataByBuisiness(businessId:any,tempId:any){
    return this.http.get(
      environment.baseUrl+`business/template/website?businessId=${businessId}&templateId=${tempId}`
    )
  }

  updateElements = new EventEmitter<any>();

  updateElementsData= new EventEmitter<any>();

  getTemplateDetails = new EventEmitter<any>();

  scrollToComponent = new EventEmitter<any>();

  updatePageName = new EventEmitter<any>();

  componentRegenration = new EventEmitter<any>();

  editorSave = new EventEmitter<any>();

  getAllBlogs(){
    return this.http.get(
      environment.baseUrl+`business/blog/getAll`
    )
  }



  //Website Builder API Integrations


  //get all business types
  getAllBusinessTypes(){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry?size=10&page=0`
    )
  }
  getSubIndustryTypes(industryId :any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry/sub/` + industryId
    )
  }
  getAllBusinessName(subCategoryId:any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/name/${subCategoryId}?size=100&page=0`
    )
  }


  getTemplateDataByBCId(bcId:any){
    return this.http.get(
      environment.baseUrl + `business/template-content/get/${bcId}`
    )
  }
  intiateTemplateData(data:any){
    return this.http.post(
      environment.baseUrl+`business/website/create`,data
    )
  }
  getTemplateDataByTemplateId(tempId:any){
    return this.http.get(
      environment.baseUrl+`business/website/?websiteId=${tempId}`
    )
  }
  updateTemplateData(data:any){
    return this.http.post(
      environment.baseUrl+`business/website/create`,data
    )
  }

  getBusinessCategoryImages(subIndustryId:any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/images?subIndustryId=`+subIndustryId
    )
  }

  openDialogWithData(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw'
    });
  }

  openDialog(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw'
    });
  }

  openDialogWithMaxHeight(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      maxHeight:'calc(100vh - 90px)'
    });
  }

  openDialogForEditorSmallScreen(componentName:any, height:any, width:any, dataModel:any){
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      position:{
        right:'0px',
        bottom:'0px'
      }
    });
  }

  openSnack(message:any, action:any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openBottomSheet(componentName: any,data:any){
    return this. _bottomSheet.open(componentName, {
      data:data,

    });
  }

  createBusiness(data:any){
    return this.http.post(
    environment.baseUrl+`business/business`,data
    )
  }

  updateBusiness(data:any){
    return this.http.put(
      environment.baseUrl+`business/business`,data
    )
  }


  getBusiness(pageNo:any,size:any){
    return this.http.get(
      environment.baseUrl+`business/business?size=${size}&page=${pageNo}`
    )
  }
  searchBusiness(size:any,pageNo:any,searchParameter:any){
    return this.http.get(
      environment.baseUrl+`business/business?size=${size}&page=${pageNo}&text=${searchParameter}`
    )
  }

  getBusinessDetailsById(id:any){
    return this.http.get(
      environment.baseUrl+`business/business/${id}`
    )
  }


  updatePage = new EventEmitter<any>();
  editComponent = new EventEmitter<any>();

  //new Apis
  // regenerateComponentCategory(componentCategoryId:any,subBusinessCategoryId:any){
  //   return this.http.get(
  //     environment.baseUrl+`admin/master/template/builder/component?categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
  //   )
  // }

  regenerateComponentCategoryWithComponentId(componentCategory:any,businessCategoryId:any,componentId:any,componentName:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/regenerate/${componentCategory}?businessCategoryId=${businessCategoryId}&componentId=${componentId}&componentName=${componentName}`
    )
  }

  contentChangeCategory(componentName:any,businessCategoryId:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/contentChange/${componentName}?businessCategoryId=${businessCategoryId}`
    )
  }



  //new components and content and template api's from chaithanya sir

  getTemplateStructure(subCategoryId:any){
    return this.http.get(
      environment.baseUrl+`admin/master/template?subIndustryId=${subCategoryId}`
    )
  }

  getComponentsData(componentId:any,componentCategoryId:any,subBusinessCategoryId:any){
    if(componentId === null){
      return this.http.get(
        environment.baseUrl+`admin/master/template/builder/component?categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
      )
    }
    else{
      return this.http.get(
        environment.baseUrl+`admin/master/template/builder/component?componentId=${componentId}&categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
      )
    }
  }
  regenerateComponents(componentCategoryId:any,subIndustryId:any,componentId:any,contentId:any){
    if(contentId != null){
      return this.http.get(
        environment.baseUrl+`admin/master/template/builder/component/regenerate?categoryId=${componentCategoryId}&subIndustryId=${subIndustryId}&componentId=${componentId}&contentId=${contentId}`
      )
    }
    else{
      return this.http.get(
        environment.baseUrl+`admin/master/template/builder/component/regenerate?categoryId=${componentCategoryId}&subIndustryId=${subIndustryId}&componentId=${componentId}`
      )
    }
  }
  changeContent(categoryId:any,subIndustryId:any,contentId:any){
    return this.http.get(
      environment.baseUrl+`admin/master/template/builder/component/content?categoryId=${categoryId}&subIndustryId=${subIndustryId}&contentId=${contentId}`
    )
  }
  mergedIndustrySubIndustry(){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry/sub?size=1000&page=0`
    )
  }


  getSubIndustryByStatus(pageNo:number,size:number,status?:boolean){
    if(status){
      return this.http.get(
        environment.baseUrl+`admin/master/business/industry/sub?size=${size}&page=${pageNo}&active=${status}`
      )
    } else {
      return this.http.get(
        environment.baseUrl+`admin/master/business/industry/sub?size=${size}&page=${pageNo}`
      )
    }
  }

  contentContentChangeCategory(componentName:any,businessCategoryId:any,categoryId:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/contentChange/${componentName}?businessCategoryId=${businessCategoryId}&ComponentId=${categoryId}`
    )
  }


  getBlogsByBuisnessId(businessId: any , blogStauts : any) {
    return this.http.get(
      environment.baseUrl + `business/blog/businessId/${businessId}?blogStatus=${blogStauts}`
    )
  }

  getBlogsBySubInstryId(subIndustryId:any){
    return this.http.get(
      environment.baseUrl+`admin/blog/getBlog/${subIndustryId}`
    )
  }

  getMasterBlogById(blogId:any){
    return this.http.get(
      environment.baseUrl+`/admin/blog/${blogId}`
    )
  }

  getTemplateCategory(){
      return this.http.get(
        environment.baseUrl+`admin/master/template/category?size=1000&page=0`
      )
  }

  //v2 integrations


  getWebsite(id:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2?websiteId=${id}`
    )
  }

  getWebsiteWithAllPages(id:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2/all?websiteId=${id}`
    )
  }

  getWebsiteWithWebCode(webCode:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2/getBy/websiteCode?websiteCode=${webCode}`
    )
  }

  generateWebsite(data:any){
    return this.http.post(
      environment.baseUrl+`admin/master/website/v2`,data
    )
  }

  updateWebsiteData(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/v2/`,data
    )
  }

  regenerateComponent(data){
    return this.http.get(
      environment.baseUrl+`admin/master/template/v2/component/regenerate?websiteId=${data.templateId}&index=${data.index}&subIndustryId=${data.subIndustryId}`
    )
  }

  changeComponentContent(data){
    return this.http.get(
      environment.baseUrl+`admin/master/template/v2/component/content?websiteId=${data.templateId}&index=${data.index}&subIndustryId=${data.subIndustryId}`
    )
  }

  getLocalComponentWithData(index:any, homePageId:any, componentId:any, subIndustryId:any){
    return this.http.get(
      environment.baseUrl+`admin/master/template/v2/component/data?index=${index}&homePageId=${homePageId}&componentId=${componentId}&subIndustryId=${subIndustryId}`
    )
  }

  updateData(data){
    return this.http.post(
      environment.baseUrl+`business/website/v2/create`,data
    )
  }

  reStylePages(data){
    return this.http.put(
      environment.baseUrl+`admin/master/website/style`,data
    )
  }


  getWebsiteData(url:string){
    return this.http.get(
      url,{responseType:'text'}
    )
  }

  getAddress(lat,lng){
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU`
    )
  }

  deletePage(pageId){
    return this.http.delete(
      environment.baseUrl+`business/website/v2?pageId=${pageId}&deleteAll=false`
    )
  }

  getMasterDepartment(pageNo:number,size:number){
    return this.http.get(
      environment.baseUrl+`admin/master/hrms/department?pageNo=${pageNo}&size=${size}`
    )
  }

  getMasterDesignation(request:any){
    return this.http.put(
      environment.baseUrl+`admin/master/hrms/designation/filter`,request
    )
  }

  //subscription apis

  getAllCountries(){
    return this.http.get(
      environment.baseUrl+`admin/master/location/country?size=1000&page=0`
    )
  }

  getAllPlans(){
    return this.http.get(
      environment.baseUrl +`business/subscription?countryId=1edf312c-47f4-6b90-a4bd-630e27ba1c08`
    )
  }

  getPlansBasedOnBusinessId(businessId){
    return this.http.get(
      environment.baseUrl+`business/subscription/plan/business?businessId=${businessId}`
    )
  }

  contactUs(data){
    return this.http.post(
      environment.baseUrl+`business/contact/simpo`,data
    )
  }

  contactUsForm(data: any){
    return this.http.post(
      environment.baseUrl+`business/contact`,data
    )
  }

  createPartner(data : any)
  {
    return this.http.post(
      environment.regenerateUrl+`affiliate-partner/create`,data
    )
  }

  captureUser(partnerKey : any)
  {
    return this.http.put(
      environment.regenerateUrl+`affiliate-partner/update?key=${partnerKey}&linkOpen=true&signUpDone=false&paymentDone=false`,''
    )
  }
  
  bookSlot(id : any, payload: any)
  {
    return this.http.put(
      environment.regenerateUrl + `affiliate-partner/${id}/schedule-meeting`,payload
    )
  }


  
}
